<template>
  <div id="app">
    <!-- {{allkey}} -->
    <router-view />
    <audio id="audio" preload="auto">
      <source src="https://images.cyoubike.com/notify.wav" />
    </audio>

    <el-button @click="aplayAudio" v-show="false" />

    <el-dialog
      :visible.sync="notifyDialog3"
      title="智慧助手"
      append-to-body
      width="800px"
    >
      <div style="width: 100%">
        <span>{{ this.msgContent }}</span>
      </div>
      <div style="width: 100%; text-align: center; margin-top: 20px">
        <el-button type="primary" @click="handleRowClick3()">查看详情
        </el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="notifyDialog4" title="表单管理" append-to-body width="800px">
      <div style="width: 100%">
        <span>{{ '【' + this.msgTitle + '】' + this.msgContent }}</span>
      </div>
      <div style="width: 100%; text-align: center; margin-top: 20px">
        <el-button type="primary" @click="handleRowClick4()">查看详情
        </el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="notifyDialog" title="告警通知" append-to-body width="800px">
      <div style="width: 100%">
        <span>{{ this.msgContent }}</span>
      </div>
      <div style="width: 100%; text-align: center; margin-top: 20px">
        <el-button type="primary" @click="handleRowClick()">查看详情
        </el-button>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="notifyDialogv2" title="纠纷通知" append-to-body width="800px">
      <div style="width: 100%">
        <span>{{ this.msgContentv2 }}</span>
      </div>
      <div style="width: 100%; text-align: center; margin-top: 20px">
        <el-button type="primary" @click="handleRowClickV2(2)">查看详情
        </el-button>
      </div>
    </el-dialog>

    <!-- 音视频拨打接听主界面，可以提取到一个组件中  start -->
    <el-dialog :visible.sync="showPbx" :modal="false" :show-close="false" :close-on-click-modal="false"
      :close-on-press-escape="false" custom-class="customClass" v-dialogDrag>
      <div class="videogroup" :style="{ height: contentHeight - 110 - isHeight + 'px' }">
        <div v-if="sipState == 0" style="text-align: center; color: #00000066; font-size: 18px">
          <!-- 从左侧选择人员进行语音、视频通话 -->
        </div>
        <div v-else style="width: 100%; height: 100%">
          <div v-show="sipState == 0">
            <div>
              <!-- 呼叫中 -->
            </div>
          </div>
          <div style="width: 100%; height: 100%" class="player" ref="player" @mouseenter="showElement"
            @mouseleave="hideElement">
            <div class="full-screen" @click="fullScreenFun" v-if="isElementVisible">
              <img v-if="!iFullscreen" src="@/assets/音视频全屏.png" alt="" />
              <img v-else src="@/assets/音视频全屏收缩.png" alt="" />
            </div>
            <div class="Calling" v-show="sipState == 1">
              <div>{{ title }}呼叫'{{ titleName }}'中...</div>
              <el-button class="stopButton" type="danger" @click="handleCancelCall">挂断
              </el-button>
            </div>
            <div v-show="sipState == 2" class="videoSuccess">
              <div v-show="title == '视频'" class="video">
                <div id="videoRemote"></div>
                <div class="video-local">
                  <div id="videoLocal" width="100%"></div>
                </div>
                <el-tooltip class="item" effect="dark" content="点击切换语音通话" placement="top">
                  <el-image title="点击切换语音通话" @click="requestVideo2Audio" class="toggle-aduio"
                    :src="require('@/assets/images/toggleAudio.png')" fit="fill"></el-image>
                </el-tooltip>
              </div>
              <div v-show="title == '语音'" class="audio">
                <el-image style="width: 120px; height: 120px; margin-bottom: 20px" :src="audioUrl"
                  :fit="'fill'"></el-image>
                <div class="demonstration">正在与'{{ titleName }}'通话中</div>
              </div>
              <div class="button">
                <div class="time">{{ str || '00:00' }}</div>
                <el-button class="stopButton" type="danger" @click="hangupClick">挂断
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 音视频拨打接听主界面，可以提取到一个组件中  end -->

    <el-dialog :visible.sync="IntercomnotifyDialogVisible" custom-class="customClass" :modal="false" :show-close="false"
      :close-on-click-modal="false" :close-on-press-escape="false" v-dialogDrag>
      <div class="IntercomnotifyDialog">
        <h3 style="text-align: left;">{{ zhzfData.userName }}</h3>
        <h2 style="color: white">{{ interphoneStateText }}</h2>
        <div style="
            width: 80px;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
          ">
          <el-image class="audioImge" v-show="isRecording && interphoneStateText !== '对方正在讲话...'"
            style="width: 80px; height: 80px" :src="audioUrl" :fit="'fill'"></el-image>

          <div v-if="interphoneStateText == '对方正在讲话...'" class="userAudio">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div>
          <el-button type="danger" @click="endTalk">挂断对讲</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Client } from '@stomp/stompjs';
import { getToken } from '@/utils/auth';
import { setItem } from './components/trina/table/localPageIndex.js';
import TRTC from 'trtc-sdk-v5';
import waitUrl from '@/assets/wait.mp3';
import hangUrl from '@/assets/hang.mp3';
import Intercom from '@/views/overviewPro/components/Intercom';

import PCMPlayer from '@/utils/interphone/pcm_player.js';
import OpusToPCM from '@/utils/interphone/opus_to_pcm.js';

import { getUserInfo, logout } from '@/api/modules/user';
import axios from 'axios';
import https from 'https';
import { now } from 'moment';


export default {
  components: {
    Intercom,
  },
  watch: {
    sipState(val) {
      console.log('sipState --> ', val);
      if (val == 2) {
        this.receipt();
        if (this.startAudio) {
          this.startAudio.pause();
        }
        if (this.timerCount) {
          console.log('取消计时器', this.timerCount);
          clearInterval(this.timerCount);
        }
      } else {
        if (val == 1) {
          this.startAudio = new Audio();
          this.startAudio.src = waitUrl;
          this.startAudio.loop = true;
          this.startAudio.play();
        }
        if (val == 0) {
          if (this.startAudio) {
            this.startAudio.pause();
          }
          let audio = new Audio();
          audio.src = hangUrl;
          audio.play();
          if (this.timerCount) {
            console.log('取消计时器3', this.timerCount);
            clearInterval(this.timerCount);
          }
        }
        this.reset();
      }
    },
  },
  data() {
    return {
      isTalk: false,
      isInCall: false,
      session: '',
      isRecording: false,
      decoder: null,
      PCMPlayer1: null,
      mediaRecorder: null, // MediaRecorder 对象
      audioChunks: [], // 存储音频片段
      audioQueue: [],
      currentlyPlaying: false,
      sourceBuffer: null,
      opusDecoder: null,
      recorder: null,
      source: null,
      audioInput: null,
      context: null,
      audioVolume: null,
      index: 0,
      socket: null,
      sendBuffer: null,
      sendBufferIndex: 0,
      pttId: 'your-ptt-id', // Replace with your actual PTT ID
      interphoneStateText: '',
      IntercomnotifyDialogVisible: false,
      zhzfData: {},
      modelId: null,
      notifyDialog3: false,
      notifyDialog4: false,
      initFlag: false,
      stompClient: '',
      alarmMsg: {},
      //音视频相关参数 end
      showPbx: false,
      notifyDialog: false,
      notifyDialogv2: false,
      msgContent: '',
      msgTitle: '',
      msgId: '',
      fromId: '',
      msgContentv2: '',
      // 0 未通话 1 呼叫中 2 通话中
      sipState: 0,
      audioUrl: require('@/assets/images/audio.png'),
      title: '',
      titleName: '',
      iFullscreen: false,
      trtc: '',
      strRoomId: '',
      isElementVisible: '',
      isHeight: 0,
      contentHeight: 0,
      startAudio: null,
      //计时器
      str: '',
      h: 0, //定义时，分，秒，毫秒并初始化为0；
      m: 0,
      ms: 0,
      s: 0,
      time: 0,
      timerCount: null,
      timerCountVal: 0,
      confirmDialog: '',
      createRoomResult: {},
      userId: '',
      //音视频相关参数 end
    };
  },

  created() {

    setItem(null, null, true);
    if (getToken()) {
      console.log('走了几遍啊');
      this.connectChannel();
      this.initTrtc();
    }

    // console.log(Recorder, '2222222222222');

    this.decoder = new OpusToPCM({ channels: 1 });

    console.log(this.decoder, '是个什么');

    this.PCMPlayer1 = new PCMPlayer(1, this.decoder.getSampleRate());









  },

  beforeDestroy() {
    console.log('APP即将销毁...');
    if (this.sipState !== 0) {
      let _this = this;
      if (_this.title === '视频') {
        console.log('停止本地视频');
        _this.trtc.stopLocalVideo();
      }
      console.log('停止本地语音');
      _this.trtc.stopLocalAudio();
      //退出房间
      _this.trtc.exitRoom();
      //取消监听
      console.log('取消bus监听');
      this.$bus.off('handleCall', this.handleCall);
      this.$bus.off('loginEvent', this.handleLoginEvent);
      this.$bus.off('loginOutEvent', this.handleLoginOutEvent);
    }
    if (this.timerCount) {
      clearInterval(this.timerCount);
    }
  },

  mounted() {

    this.$bus.on('initIntercomWs', (id) => {
      this.fetchConsoleInfo(id);
    })

    this.$bus.on('interphone', (name, id) => {
      this.interphoneEvent(name, id);
    });

    this.decoder.on('decode', (pcmData) => {
      this.PCMPlayer1.feed(pcmData); /* PCM data */
    });

    //开启监听
    console.log('开启bus监听');
    this.$bus.on('handleCall', this.handleCall);
    this.$bus.on('removeUser', this.removeUser);
    this.$bus.on('exitOnlineUser', this.exitOnlineUser);
    this.$bus.on('loginEvent', this.handleLoginEvent);

    this.$bus.on('loginOutEvent', this.handleLoginOutEvent);

    this.setContentHeight(); // 初始化内容区域的高度
    window.addEventListener('resize', this.setContentHeight); // 监听窗口大小变化
    if (sessionStorage.getItem("logoutOn")){
      logout(sessionStorage.getItem('userId'));
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
      this.$store.dispatch('tagsView/delAllViews');
      console.log('触发退出登录事件');
      sessionStorage.removeItem("logoutOn");
      this.$bus.emit('loginOutEvent');
      sessionStorage.removeItem('token');
    }
    setTimeout(() => {}, 1000);
  },

  updated() {},

  methods: {
    handleKeyDown(event) {
      if (event.code === 'Space' && !this.isRecording) {
        this.start(); // 按下空格键时开始录音
        console.log('按下了');

      }
    },

    handleKeyUp(event) {
      if (event.code === 'Space' && this.isRecording) {
        this.stopAudio(); // 松开空格键时停止录音
        console.log('松开了');

      }
    },
    initTalk() {
      this.context = null;
      this.audioVolume = null;

      if (!this.context) {
        this.context = new AudioContext();
      }
      this.audioVolume = this.context.createGain();
      // 初始化
      let that = this;
      let arrBuffer = new ArrayBuffer(320 * 2);
      let sendBuffer = new DataView(arrBuffer);
      let sendBufferindex = 0;
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(function (stream) {
          that.audioInput = that.context.createMediaStreamSource(stream);
          that.recorder = that.context.createScriptProcessor(1024, 1, 1);
          that.recorder.onaudioprocess = function (e) {

            let buffer = e.inputBuffer.getChannelData(0);
            let arrayBuffer = that.context.createBuffer(
              1,
              1024,
              that.context.sampleRate
            );
            let nowBuffering = arrayBuffer.getChannelData(0);
            for (let i = 0; i < arrayBuffer.length; i++) {
              nowBuffering[i] = buffer[i];
            }
            let offctx = new OfflineAudioContext(
              1,
              parseInt((1024 * 16000) / that.context.sampleRate),
              16000
            );
            that.source = offctx.createBufferSource();
            that.source.buffer = arrayBuffer;
            that.source.connect(offctx.destination);
            that.source.start();
            offctx.startRendering().then(function (renderedBuffer) {
              let channetData = renderedBuffer.getChannelData(0);
              that.source.stop();
              let index = 0;
              let length = channetData.length;
              while (index < length) {
                let selectBuffer = channetData[index];
                let s = Math.max(-1, Math.min(1, selectBuffer));
                let point = s < 0 ? s * 0x8000 : s * 0x7fff;
                if (sendBufferindex < 320 * 2) {
                  sendBuffer.setInt16(sendBufferindex, point, true);
                } else {
                  ++that.index;
                  that.workerMessage(new Int8Array(arrBuffer), that.index); // 采集的语音数据二进制 index 是序号
                  sendBuffer = new DataView(arrBuffer);
                  sendBufferindex = 0;
                  sendBuffer.setInt16(sendBufferindex, point, true);
                }
                index++;
                sendBufferindex = sendBufferindex + 2;
              }
            });
          };
        })
        .catch((e) => {
          console.log('e: ', e);
        });

      console.log(this.audioInput, this.audioVolume, this.recorder);
    },
    endTalk() {

      window.removeEventListener('keydown', this.handleKeyDown); // 组件销毁时移除监听器
      window.removeEventListener('keyup', this.handleKeyUp);     // 组件销毁时移除监听器

      console.log('结束对讲');

      this.IntercomnotifyDialogVisible = false;
      this.isRecording = false;

      let handshakeMessage = {
        msgId: this.generateMsgId(),
        sourceId: this.zhzfData.uid,
        msgType: 32,
        tgId: this.zhzfData.uid,
      };

      this.socket.send(JSON.stringify(handshakeMessage));
    },
    startTalk() {
      console.log('开始对讲');
      //开始对讲
      this.interphoneStateText = '松开空格结束讲话';

      this.audioInput?.connect(this.audioVolume);
      this.audioVolume?.connect(this.recorder);
      this.recorder?.connect(this.context.destination);

    },
    stopAudio() {
      // 停止对讲
      this.recorder?.disconnect();

      let handshakeMessage = {
        msgId: this.generateMsgId(),
        sourceId: this.zhzfData.uid,
        msgType: 52,
        tgId: this.zhzfData.uid,
        pttId: this.pttId,
      };
      console.log('结束讲话', handshakeMessage);

      this.interphoneStateText = '长按空格讲话';

      this.isRecording = false;

      this.socket.send(JSON.stringify(handshakeMessage));
    },
    concatenate(view, offset, arrays) {
      // 将传入的 ArrayBuffer 转为 Uint8Array 以便访问每个字节
      let uint8Array = new Uint8Array(arrays);

      for (let i = 0; i < uint8Array.byteLength; i++) {
        view.setInt8(offset + i, uint8Array[i]);
      }

      return view;
    },
    writeString(view, offset, string) {
      for (let i = 0; i < string.length; i++) {
        view.setUint8(offset + i, string.charCodeAt(i));
      }
    },
    workerMessage(buffer, index) {
      let pttId = this.pttId; // PTT ID
      let Buffer = new ArrayBuffer(26 + pttId.length + buffer.length); // 创建数据缓冲区
      let newBuffer = new DataView(Buffer);

      newBuffer.setInt32(0, 56); // 消息类型：56
      newBuffer.setInt8(4, 1); // 版本号：1
      newBuffer.setInt32(5, parseInt(this.zhzfData.tgId)); // 对讲组 ID
      newBuffer.setInt32(9, parseInt(this.zhzfData.uid)); // 发送者 ID
      newBuffer.setInt32(13, pttId.length); // PTT ID 长度
      this.writeString(newBuffer, 17, pttId); // 写入 PTT ID
      newBuffer.setInt8(17 + pttId.length, 1); // 编码方式（1 为 PCM）
      newBuffer.setInt32(18 + pttId.length, index); // 音频数据序列号
      newBuffer.setInt32(22 + pttId.length, buffer.length); // 音频数据长度

      // 接收 concatenate 函数的返回值
      newBuffer = this.concatenate(newBuffer, 26 + pttId.length, buffer);

      this.socket.send(newBuffer.buffer); // 通过 WebSocket 发送消息

    },
    fetchConsoleInfo(id) {

      console.log('看看id', id);

      let url = 'https://zhzf.tfryb.com:39006/third/v1/get_console_info';
      let params = new URLSearchParams();

      let account = 'jjqzfwgly';

      if (id == 4604010353107072) {
        account = 'jjqdhjdb'
      }

      params.append('account', account);
      params.append(
        'key',
        'OeO7DgvDPIXKAkb39NkONGFzybeNKe2GcvxlVHyhl/BrC3suQB9j8FfK5yZL9Pk8'
      );

      axios
        .post(url, params, {
          httpsAgent: new https.Agent({
            rejectUnauthorized: false, // 不验证证书（仅用于开发环境）
          }),
        })
        .then((response) => {
          this.zhzfData = response.data.data;
          this.initWebSocket(this.zhzfData.ws);
          console.log(response.data.data, '看看信息');
        })
        .catch((error) => {
          console.error('Error connecting to the server:', error);
        });
    },
    initWebSocket(wsUrl) {
      this.socket = new WebSocket(wsUrl);
      this.socket.binaryType = 'blob';

      this.socket.onopen = () => {

        console.log('已连接到对讲机WebSocket');

        // 握手认证
        let handshakeMessage = {
          msgId: this.generateMsgId(),
          msgType: 1,
          sourceId: this.zhzfData.uid,
          targetId: 0,
        };

        this.socket.send(JSON.stringify(handshakeMessage));
      };

      this.socket.onmessage = (event) => {
        if (typeof event.data === 'string') {
          let data = JSON.parse(event.data);

          if (data.msgType == 2 && data.status == 1) {
            console.log('对讲机系统认证成功', JSON.parse(event.data));

            setInterval(() => {
              let handshakeMessage = {
                msgId: this.generateMsgId(),
                msgType: 3,
                sourceId: this.zhzfData.uid,
                targetId: 0,
              };
              this.socket.send(JSON.stringify(handshakeMessage));
              console.log('发送了心跳消息');
            }, 30000);
          }

          if (data.msgType == 31 && data.status == 0) {
            console.log('进入对讲群组成功', JSON.parse(event.data));
            this.zhzfData.tgId = data.tgId;
          } else if (data.msgType == 31 && data.status == 1) {
            console.log('进入对讲群组失败', JSON.parse(event.data));
          }

          if (data.msgType == 71) {
            if (data.status == 0) {


              this.initTalk();
              this.isInCall = false;

              this.$message({
                type: 'success',
                message: '对方已接听',
              });

              this.interphoneStateText = '对方已接听';

              setTimeout(() => {
                this.interphoneStateText = '长按空格讲话';
                window.addEventListener('keydown', this.handleKeyDown);
                window.addEventListener('keyup', this.handleKeyUp);
              }, 500);

            }
            if (data.status == 1) {
              this.$message({
                type: 'error',
                message: '对方拒绝',
              });
              this.IntercomnotifyDialogVisible = false;
            }
            if (data.status == 2) {
              this.$message({
                type: 'warning',
                message: '对方正在忙',
              });
              this.IntercomnotifyDialogVisible = false;
            }
            if (data.status == 3) {
              this.$message({
                type: 'error',
                message: '对方已离线',
              });
              this.IntercomnotifyDialogVisible = false;
            }
            if (data.status == 4) {
              this.$message({
                type: 'error',
                message: '呼叫超时',
              });
              this.IntercomnotifyDialogVisible = false;
            }
          }

          if (data.msgType == 55) {
            this.interphoneStateText = '长按空格讲话';
          }

          if (data.msgType == 54) {
            this.interphoneStateText = '对方正在讲话...';
          }

          if (data.msgType == 51) {

            this.pttId = data.pttId;

            if (data.status == 0) {
              this.startTalk();
            } else {

              this.interphoneStateText = '对方拒绝';

              setTimeout(() => {
                this.IntercomnotifyDialogVisible = false;
              }, 1000)

            }

            if (data.status == 4) {

              this.interphoneStateText = '您被禁言';

            }

          }

          if (data.msgType == 33) {

            if (data.status == 0) {
              console.log(this.session, '11111111111');
              let status = 0

              if (this.isInCall) {
                status = 1
              } else {
                status = 0
              }

              let handshakeMessage = {
                msgId: this.generateMsgId(),
                sourceId: this.zhzfData.uid,
                targetId: this.userId,
                msgType: 72,
                status: status,
                session: this.session,
              };

              this.socket.send(JSON.stringify(handshakeMessage));

              this.$message({
                type: 'error',
                message: status == 0 ? '通话已结束' : '已取消',
              });

            }
          }

          if (data.msgType == 72) {

            // this.$message({
            //   type: 'error',
            //   message: '通话已结束'
            // });

            this.interphoneStateText = '对方挂断';

            setTimeout(() => {
              this.IntercomnotifyDialogVisible = false;
            }, 1000)
          }

          // console.log('收到消息了', JSON.parse(event.data));

        } else {

          // console.log('收到消息了event', event);

          // 将event.data转成ArrayBuffer格式
          let reader = new FileReader();
          reader.onload = () => {

            if (!this.IntercomnotifyDialogVisible) {
              return
            }



            let arrayBuffer = reader.result;
            this.getAudioMsg(new Uint8Array(arrayBuffer), arrayBuffer);
          };

          reader.readAsArrayBuffer(event.data);
        }
      };

      this.socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      this.socket.onclose = () => {
        console.log('WebSocket connection closed');
      };
    },

    getAudioMsg(buffer, data) {
      let newBuffer = new DataView(data);
      let uid = newBuffer.getInt32(9); //语音发送者的id
      let pttIdLen = buffer[16]; //pttId长度
      let audioData = this.getAudioBuff(buffer, 26 + pttIdLen); //获取语音数据
      console.log(audioData, '语音数据');
      this.decoder.decode(audioData);
    },

    getAudioBuff(buffer, audioStart) {
      return buffer.slice(audioStart, buffer.byteLength);
    },

    start() {


      if (this.isRecording) {
        return;
      }

      let handshakeMessage = {
        msgId: this.generateMsgId(),
        sourceId: this.zhzfData.uid,
        msgType: 50,
        tgId: this.zhzfData.uid,
      };


      this.isRecording = true;
      this.socket.send(JSON.stringify(handshakeMessage));
      console.log('申请讲话', handshakeMessage);
    },

    generateMsgId() {
      return 'msg-' + Math.random().toString(36).substr(2, 9);
    },

    // handleKeyDown(event) {
    //   // 检查是否按下空格键，并确保不会重复触发
    //   if (event.code === 'Space' && !event.repeat) {
    //     this.toggleRecording(); // 切换录音状态
    //   }
    // },
    // toggleRecording() {
    //   if (this.interphoneStateText !== '对方正在讲话...') {
    //     if (this.isRecording) {
    //       this.interphoneStateText = '单击空格讲话';
    //       this.stopAudio(); // 如果正在录音，则停止录音
    //     } else {
    //       this.start(); // 如果没有录音，则开始录音
    //       this.interphoneStateText = '单击空格结束讲话';
    //     }

    //     this.isRecording = !this.isRecording; // 切换录音状态
    //   }
    // },
    interphoneEvent(name, id) {
      console.log(name, id, '准备打');

      if (this.IntercomnotifyDialogVisible) {
        this.$message({
          type: 'warning',
          message: '正在对讲中'
        });
        return;
      }


      // 申请加入单呼群组
      let handshakeMessage = {
        msgId: this.generateMsgId(),
        sourceId: this.zhzfData.uid,
        targetId: 0,
        msgType: 30,
        type: 1,
        tgId: this.zhzfData.uid,
      };

      this.socket.send(JSON.stringify(handshakeMessage));


      this.$confirm(`确认与${name}对讲吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let handshakeMessage = {
          msgId: this.generateMsgId(),
          sourceId: this.zhzfData.uid,
          msgType: 70,
          session: name,
          type: 1,
          targetId: id,
        };
        this.userId = id;
        this.session = name;
        this.zhzfData.userName = name;
        this.isInCall = true;

        console.log('呼叫了', handshakeMessage);
        this.interphoneStateText = '正在请求呼叫...';
        this.socket.send(JSON.stringify(handshakeMessage));
        this.IntercomnotifyDialogVisible = true;

      });
    },
    handleRowClick3() {
      this.notifyDialog3 = false;
      this.$router.push({
        name: 'SmartController',
        params: {
          id: this.modelId,
        },
      });
    },
    handleRowClick4() {
      this.notifyDialog4 = false;

      this.$router.push({
        name: 'FromManager',
        params: {
          id: this.fromId,
        },
      });
    },
    handleLoginEvent() {
      this.connectChannel();
      this.initTrtc();
    },
    handleLoginOutEvent() {
      this.disConnectChannel();
      this.socket = null;
    },
    //开始
    receipt: function () {
      this.time = setInterval(this.timer, 50);
    },
    reset: function () {
      //重置
      clearInterval(this.time);
      this.h = 0;
      this.m = 0;
      this.ms = 0;
      this.s = 0;
      this.str = '00:00:00';
    },
    timer: function () {
      //定义计时函数
      this.ms = this.ms + 50; //毫秒
      if (this.ms >= 1000) {
        this.ms = 0;
        this.s = this.s + 1; //秒
      }
      if (this.s >= 60) {
        this.s = 0;
        this.m = this.m + 1; //分钟
      }
      if (this.m >= 60) {
        this.m = 0;
        this.h = this.h + 1; //小时
      }
      this.str =
        this.toDub(this.h) +
        ':' +
        this.toDub(this.m) +
        ':' +
        this.toDub(this.s) +
        '';
    },
    toDub: function (n) {
      //补0操作
      if (n < 10) {
        return '0' + n;
      } else {
        return '' + n;
      }
    },
    setContentHeight() {
      this.isHeight = 0;
      this.contentHeight = window.innerHeight - 180; // 设置内容区域的高度为窗口的高度
    },
    showElement() {
      console.log('showElement');
      // clearTimeout(this.timeoutId);
      this.isElementVisible = true;
    },
    hideElement() {
      console.log('hideElement');
      // this.timeoutId = setTimeout(() => {
      this.isElementVisible = false;
      // }, 5000);
    },
    fullScreenFun() {
      var fullscreenEnabled =
        document.fullscreenEnabled ||
        document.mozFullScreenEnabled ||
        document.webkitFullscreenEnabled ||
        document.msFullscreenEnabled;

      if (fullscreenEnabled) {
        let de = this.$refs.player;

        if (this.iFullscreen) {
          //关闭全屏
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          }
          this.iFullscreen = false;
          return;
        } else {
          //打开全屏
          if (de.requestFullscreen) {
            de.requestFullscreen();
          } else if (de.mozRequestFullScreen) {
            de.mozRequestFullScreen();
          } else if (de.webkitRequestFullScreen) {
            de.webkitRequestFullScreen();
          }
          this.iFullscreen = true;
        }
      }
    },

    connectChannel() {
      let _this = this;
      if (_this.stompClient) {
        console.log('websocket已创建');
        return;
      }
      //写一个uuid

      let url = process.env.VUE_APP_SIP_CALL_WEBSOCKET;
      console.log('连接websocket -->', url);
      _this.stompClient = new Client({
        brokerURL: url,
        reconnectDelay: 3000,
        connectHeaders: {
          Authorization: getToken(), // 获取token
          loginUUID: sessionStorage.getItem("loginUUID"),
        },
        onConnect: () => {
          console.log('WS已链接...');
          _this.subscribe();

          //音视频相关监听
          _this.subscribeRegisterResult();
          _this.subscribeInvite();
          _this.subscribeCreateRoomResult();
          _this.subscribeCancelWaiting();
          _this.subscribeVideo2AudioResult();
          _this.subscribeAnswerResult();

          //音视频注册
          _this.stompClient.publish({
            destination: '/app/register',
            body: JSON.stringify({ userId: this.xy_userId }),
          });
        },
        onDisconnect: () => {
          console.log('WS断开链接...');
        },
      });
      //激活websocket
      _this.stompClient.activate();
    },
    initTrtc() {
      //创建音视频对象
      if (this.trtc) {
        console.log('TRTC已创建');
        return;
      }
      this.trtc = TRTC.create();
      this.subscribeTrtcEvent();
    },
    subscribeTrtcEvent() {
      this.trtc.on(TRTC.EVENT.REMOTE_AUDIO_UNAVAILABLE, (event) => {
        console.log('对方关闭了语音');
      });

      this.trtc.on(TRTC.EVENT.REMOTE_VIDEO_UNAVAILABLE, (event) => {
        this.$message({ type: 'info', message: '对方关闭了视频' });
      });

      this.trtc.on(TRTC.EVENT.REMOTE_VIDEO_AVAILABLE, (event) => {
        let userId = event.userId;
        console.log('远程视频可用.. ' + userId);
        let streamType = event.streamType;
        this.trtc.startRemoteVideo({ userId, streamType, view: 'videoRemote' });
      });

      this.trtc.on(TRTC.EVENT.REMOTE_USER_ENTER, (event) => {
        this.sipState = 2;
        let userId = event.userId;
        console.log('用户进入了房间userId:', userId);
        this.$message({ type: 'info', message: '已接通' });
      });

      this.trtc.on(TRTC.EVENT.REMOTE_USER_EXIT, (event) => {
        let userId = event.userId;
        console.log('用户退出了房间userId:', userId);
        this.hangupClick();
      });

      this.trtc.on(TRTC.EVENT.KICKED_OUT, (event) => {
        if (event.reason === 'kick') {
          this.$message({ type: 'info', message: '你被踢出了房间' });
        }
        console.log('房间解散了 reason:', event.reason);
        this.hangupClick();
      });
      //监听网络状态
      this.trtc.on(TRTC.EVENT.NETWORK_QUALITY, (event) => {
        if (event.uplinkNetworkQuality > 3) {
          this.$message({ type: 'info', message: '您的网络不佳' });
        }
        if (event.downlinkNetworkQuality > 3) {
          this.$message({ type: 'info', message: '对方网络不佳' });
        }
        console.log(
          `network-quality, uplinkNetworkQuality:${event.uplinkNetworkQuality}, downlinkNetworkQuality: ${event.downlinkNetworkQuality}`
        );
        console.log(`uplink rtt:${event.uplinkRTT} loss:${event.uplinkLoss}`);
        console.log(
          `downlink rtt:${event.downlinkRTT} loss:${event.downlinkLoss}`
        );
      });
    },
    answerCall(strRoomId) {
      this.stompClient.publish({
        destination: '/app/answer',
        body: JSON.stringify({ strRoomId: this.strRoomId }),
      });
    },
    //进入音视频房间
    enterRoom(enterRoomParam) {
      let _this = this;
      let currentUserId;
      console.log('enterRoomParam -->', enterRoomParam);
      if (enterRoomParam.command === 'call_in') {
        currentUserId = enterRoomParam.receiverUserId;
      }
      if (enterRoomParam.command === 'call_out') {
        currentUserId = enterRoomParam.ownerId;
      }
      this.strRoomId = enterRoomParam.strRoomId;
      _this.trtc
        .enterRoom({
          strRoomId: enterRoomParam.strRoomId,
          sdkAppId: enterRoomParam.sdkAppId,
          userId: currentUserId,
          userSig: enterRoomParam.userSig,
          scene: 'rtc',
          role: 'anchor',
        })
        .then((res) => {
          console.log('trtc.enterRoom ==>', res);
          //视频
          if (enterRoomParam.callType === 'video') {
            console.log('本地视频 --> ');
            _this.trtc.startLocalAudio();
            _this.trtc.startLocalVideo({
              view: 'videoLocal',
              option: {
                //profile配置 https://cloud.tencent.com/document/product/647/74680
                option: { profile: '360p' },
              },
            });
          } else {
            //音频
            _this.trtc.startLocalAudio();
          }
        })
        .catch((err) => {
          console.log('无法拨打音视频 ==> ', err);
          _this.$message({ type: 'info', message: '无法拨打音视频' });
          if (_this.timerCount) {
            clearInterval(_this.timerCount);
          }
        });
    },
    handleCancelCall() {
      this.$message({
        type: 'info',
        message: '已取消呼叫',
      });
      this.cancelCall();
      this.sipState = 0;
      this.showPbx = false;
    },
    // 挂断电话
    hangupClick() {
      let _this = this;
      if (_this.title === '视频') {
        console.log('停止本地视频');
        _this.trtc.stopLocalVideo();
      }
      console.log('停止本地语音');
      _this.trtc.stopLocalAudio();
      //退出房间
      _this.trtc.exitRoom();
      if (_this.sipState === 1) {
        _this.$message({
          type: 'info',
          message: '已取消',
        });
        _this.cancelCall();
      } else if (this.sipState === 2) {
        _this.$message({
          type: 'info',
          message: '通话结束',
        });
        _this.exitRoom();
      } else if (this.sipState === 99) {
        _this.$message({
          type: 'info',
          message: '对方无应答',
        });
      }
      _this.sipState = 0;
      _this.showPbx = false;
    },
    handleToggleAudio() {
      //关闭本地摄像头
      this.trtc.stopLocalVideo();
      //关闭远程摄像头
      this.trtc.stopRemoteVideo({ userId: '*' });
      this.title = '语音';
    },
    cancelCall() {
      this.stompClient.publish({
        destination: '/app/cancel',
        body: JSON.stringify({ strRoomId: this.strRoomId }),
      });
    },
    cancelCallV2() {
      this.stompClient.publish({
        destination: '/app/cancel',
        body: JSON.stringify({ strRoomId: this.strRoomId, timeout: 30 }),
      });
    },
    rejectCall() {
      this.stompClient.publish({
        destination: '/app/reject',
        body: JSON.stringify({ strRoomId: this.strRoomId }),
      });
    },
    exitRoom() {
      this.stompClient.publish({
        destination: '/app/exitRoom',
        body: JSON.stringify({ strRoomId: this.strRoomId }),
      });
    },
    removeUser(val){
      this.stompClient.publish({
        destination: '/app/removeOnlineUser',
        body: JSON.stringify(val),
      });
    },
    handleCall(type, row, showConfirm) {
      console.log('即将拨打电话 ==> ', type, row, showConfirm);
      let _this = this;
      _this.titleName = row.userName;
      _this.title = type === 'video' ? '视频' : '语音';
      //有弹框确认
      if (showConfirm) {
        _this
          .$confirm(`确定${this.title}呼叫'${row.userName}'?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          })
          .then(() => {
            console.log('呼叫 ... ' + row.id);
            _this.timerCountVal = 30;
            _this.timerCount = setInterval(_this.onTimerCount, 1000);
            _this.stompClient.publish({
              destination: '/app/createRoom',
              body: JSON.stringify({ targetUserIds: [row.id], callType: type }),
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消呼叫',
            });
          });
      } else {
        //无弹框确认
        _this.stompClient.publish({
          destination: '/app/createRoom',
          body: JSON.stringify({ targetUserIds: [row.id], callType: type }),
        });
      }
    },
    onTimerCount() {
      this.timerCountVal = this.timerCountVal - 1;
      console.log('倒计时中 -->', this.timerCountVal);
      if (this.timerCountVal < 0) {
        console.log('取消计时器2');
        clearInterval(this.timerCount);
        this.$message({ type: 'info', message: '无人接听' });
        this.cancelCallV2();
        this.sipState = 99;

        //没做区分 99 是超时状态
        this.hangupClick();
      }
    },
    disConnectChannel() {
      console.log('WS即将断开...');
      if (this.stompClient) {
        this.stompClient.deactivate();
        this.stompClient = null;
      }
      location.reload();
    },
    handleRowClick() {
      // 查看当前路由界面
      let path = this.$router.history.current.path;

      console.log(this.$router.history.current.path, 'name');

      if (path == '/overviewPro' || path == '/bike/realTimeControlQiao') {
        this.$bus.$emit('alarmMsg', this.alarmMsg);

        this.notifyDialog = false;
      } else {
        this.notifyDialog = false;

        this.$confirm('查看告警信息需要跳转到新的页面，确定要跳转吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          getUserInfo().then((res) => {
            if (res.data.expandMap.dashboardId == 1) {
              this.$router.push({
                path: '/overviewPro',
                query: {
                  map: {
                    alarmLon: this.alarmMsg.alarmLon,
                    alarmLat: this.alarmMsg.alarmLat,
                  },
                },
              });
            } else {
              this.$router.push({
                path: '/bike/realTimeControlQiao',
                query: {
                  map: {
                    alarmLon: this.alarmMsg.alarmLon,
                    alarmLat: this.alarmMsg.alarmLat,
                  },
                },
              });
            }
          });
        });
      }
    },
    handleRowClickV2(type) {
      this.notifyDialogv2 = false;
      this.$router.push({
        path: '/msgSend',
        query: { type },
      });
    },
    aplayAudio() {
      let audio = document.getElementById('audio');
      audio.currentTime = 0;
      audio.play();
    },
     extractUUIDFromMessageId(messageId) {
      // 使用正则表达式匹配UUID部分
      const match = messageId.match(/([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})/i);
      if (match && match[1]) {
        return match[1];
      }
      return messageId;
    },
    subscribeRegisterResult() {
      this.stompClient.subscribe('/user/trtc/registerResult', (response) => {
        console.log('注册成功:' + response.body);
        console.log('使用message-id:' + response.headers['message-id']);
        const fullMessageId = response.headers['message-id'];
        const uuidPart = this.extractUUIDFromMessageId(fullMessageId);
        if(uuidPart) {
          console.log('使用message-id提取的UUID:', uuidPart);
          localStorage.setItem('simpSessionId', uuidPart);  // 存储提取的UUID
        } else {
          console.error('无法从message-id中提取UUID');
        }
      });
    },
    exitOnlineUser(val) {
        const receivedUUID = val.loginUUID;
        const currentUUID = sessionStorage.getItem('loginUUID');
        if(currentUUID === receivedUUID){
          sessionStorage.setItem("logoutOn", 'true');
          this.aplayAudio();
          this.$msgbox({
            title: '提示',
            message: '登录状态已过期，请重新登陆',
            showCancelButton: false, // 隐藏取消按钮
            confirmButtonText: '重新登陆',
            type: 'warning',
            showClose: false, // 隐藏关闭按钮
            closeOnClickModal: false // 点击模态背景不关闭弹窗
          }).then(() => {
            logout(sessionStorage.getItem('userId'));
            this.$router.push(`/login?redirect=${this.$route.fullPath}`);
            this.$store.dispatch('tagsView/delAllViews');
            console.log('触发退出登录事件');
            sessionStorage.removeItem("logoutOn");
            this.$bus.emit('loginOutEvent');
            sessionStorage.removeItem('token');
          });

        } else {
          console.log("使用message-id接收到的消息不是为当前会话准备的");
        }
    },
    subscribeCancelWaiting() {
      this.stompClient.subscribe('/user/trtc/cancelWaiting', (response) => {
        this.$message({ type: 'info', message: '对方取消了通话' });
        console.log('对方取消了通话:' + response.body);
        this.sipState = 0;
        this.showPbx = false;
        if (this.confirmDialog) {
          console.log('confirmDialog -->', this.confirmDialog);
          //TODO 关闭对话框
          this.$confirm.close();
        }
      });
    },
    requestVideo2Audio() {
      this.stompClient.publish({
        destination: '/app/video2Audio',
        body: JSON.stringify({ strRoomId: this.strRoomId }),
      });
    },
    subscribeVideo2AudioResult() {
      this.stompClient.subscribe('/user/trtc/video2AudioResult', (response) => {
        this.handleToggleAudio();
      });
    },
    subscribeAnswerResult() {
      this.stompClient.subscribe('/user/trtc/answerResult', (response) => {
        console.log('对方已进入房间 --> ', this.createRoomResult);
        this.enterRoom(this.createRoomResult);
      });
    },
    subscribeOrderMessage() {
      let _this = this;
      this.stompClient.subscribe('/user/order/message', function (response) {
        let body = JSON.parse(response.body);
        //通过eventbus发送事件
        _this.$bus.emit('calcDistance', body);
      });
    },
    subscribeInvite() {
      let _this = this;
      _this.stompClient.subscribe('/user/trtc/invite', (response) => {
        console.log('邀请进入房间', response.body);
        let result = JSON.parse(response.body);
        if (result.code === 200) {
          _this.titleName = result.data.ownerName;
          _this.sipState = 1;
          let callType = result.data.callType === 'video' ? '视频' : '语音';
          _this.title = callType;
          _this.confirmDialog = _this
            .$confirm(
              `确定接听'${result.data.ownerName}'的${callType}电话吗?`,
              '提示',
              {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              }
            )
            .then(() => {
              _this.showPbx = true;
              _this.enterRoom(result.data);
              _this.answerCall(result.data.strRoomId);
            })
            .catch(() => {
              _this.sipState = 0;
              _this.rejectCall();
              this.$message({
                type: 'info',
                message: '已拒绝',
              });
            });
        }
      });
    },
    subscribeCreateRoomResult() {
      let _this = this;
      _this.stompClient.subscribe('/user/trtc/createRoomResult', (response) => {
        let result = JSON.parse(response.body);
        console.log('createRoomResult ===> ', result);
        if (result.code === 200) {
          _this.showPbx = true;
          _this.sipState = 1;
          _this.createRoomResult = result.data;
          _this.strRoomId = _this.createRoomResult.strRoomId;
          // _this.enterRoom(result.data)
        } else {
          //错误提示
          this.$message({ type: 'info', message: result.data });
          if (this.timerCount) {
            console.log('取消计时器4', this.timerCount);
            clearInterval(this.timerCount);
          }
        }
      });
    },
    //订阅消息
    subscribe() {
      let _this = this;
      this.stompClient.subscribe('/user/queue/messagePopulation', function (response) {
        _this.aplayAudio();
        let body = JSON.parse(response.body)
        let val = {
          flag: true,
          desc: body.submitDesc,
          id: body.id,
          titleTrue: body.titleTrue,
          rowDataPro: body,
          hasRead: body.hasRead
        }
        console.log(body, 'taskAlarmTrigger')
        _this.$bus.emit('taskAlarmTrigger', val)
      });
      //t账号的通知
      this.stompClient.subscribe('/user/trtc/removeOnlineUser', function (response) {

        let body = JSON.parse(response.body)
        let val = {
          removeUserId: body.removeUserId,
          loginUUID: body.loginUUID
        }
        console.log(body, 'taskAlarmTrigger')
        _this.$bus.emit('exitOnlineUser', val)
      });
      this.stompClient.subscribe('/user/queue/Approve', function (response) {
        _this.aplayAudio();
        let body = JSON.parse(response.body)
        let val = {
          flag: true,
          userName: body.userName,
          mobile: body.mobile,
          id: body.id,
          hasRead: body.hasRead
        }
        _this.$bus.emit('userApproverTrigger', val)
        console.log(body, 'bodyuserApproverTrigger')
      });
      this.stompClient.subscribe('/user/queue/messageAlarm', function (response) {
        let body = JSON.parse(response.body)
        _this.msgContent = body.msgContent;
        _this.alarmMsg = body;
        _this.notifyDialog = true;
        console.log('messageAlarm', body)
      });


      this.stompClient.subscribe('/user/queue/smartmMsg', function (response) {
        let body = JSON.parse(response.body)
        console.log('smartmMsg', body)
        _this.msgContent = body.content;
        _this.modelId = body.modelId;
        //  _this.alarmMsg=body;
        _this.notifyDialog3 = true;

      });


      this.stompClient.subscribe('/user/queue/formManagerMsg', function (response) {
        let body = JSON.parse(response.body)
        console.log('formManagerMsg', body)
        _this.msgTitle = body.msgTitle;
        _this.msgContent = body.msgContent;
        _this.msgId = body.id;
        _this.fromId = body.formManagerId;
        _this.notifyDialog4 = true;
      });


      this.stompClient.subscribe('/user/queue/messagePolice', function (response) {
        _this.aplayAudio();
        let body = JSON.parse(response.body)
        let val = {
          flag: true,
          descAll: body.submitDesc,
          titleAll: body.titleAll,
          id: body.id,
          hasRead: body.hasRead,
          allData: body
        }
        _this.$bus.emit('userPoliceTrigger', val)
        console.log(body, 'userPoliceTrigger发任务的')
      });
      this.stompClient.subscribe('/user/queue/messageDispute', function (response) {
        _this.aplayAudio();
        let body = JSON.parse(response.body)
        _this.msgContentv2 = body.msgContent;
        _this.disputeMsg = body;
        _this.notifyDialogv2 = true;
        console.log(body, 'messageDispute纠纷调处的信息')
      });
    },
  },
  // destroyed(){
  //   clearAllItem()
  // }
};
</script>
<style lang="scss" scoped>
#app {
  width: 100%;
  height: 100vh;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  //overflow: hidden;
}

.videogroup {
  position: relative;
  flex: 1;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: tag-fade-in2 0.7s ease-out forwards;
}

/deep/ .customClass {
  width: 500px;
  background-color: transparent !important;
  box-shadow: none !important;

  .el-dialog__body {
    padding: 20px !important;
    border-radius: 10px;
  }
}

/deep/ .el-dialog {
  background-color: none !important;
  box-shadow: none !important;
}

.el-icon-bell {
  position: relative;

  .item {
    position: absolute;
    right: -18px;
    top: 0;
  }
}

.audioImge {
  animation: tag-fade-in 0.8s ease-out forwards;
}

@keyframes tag-fade-in2 {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.player {
  position: relative;
}

//呼叫中
.Calling {
  width: 100%;
  height: 100%;
  color: #fff;
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  animation: tag-fade-in 0.8s ease-out forwards;

  div {
    margin-bottom: 50px;
  }
}

.time {
  color: #fff;
  z-index: 10000;
  position: absolute;
  bottom: 100px;
}

.stopButton {
  position: absolute;
  bottom: 40px;
}

.callButton {
  position: absolute;
  left: 30px;
  background-color: #ffffff;
  backdrop-filter: blur(15px);

  /* 调整模糊程度，以适应您的需求 */
  .Button {
    cursor: pointer;
    display: flex;

    div {
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
      }

      span {
        font-size: 14px;
        margin-left: 10px;
        color: #0052d9ff;
      }
    }
  }

  .Button2 {
    display: flex;
    cursor: not-allowed;

    div {
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
      }

      span {
        font-size: 14px;
        margin-left: 10px;
        color: #00000066;
      }
    }
  }

  ::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background: linear-gradient(to bottom right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5));
    filter: blur(15px);
    z-index: -1;
  }
}

.videoSuccess {
  width: 100%;
  height: 100%;
  background: #000000;
  position: relative;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // flex-direction: column;
}

.video {
  display: flex;
  background: black;
  position: relative;
  // border: 1px solid lightgrey;
  width: 100%;
  height: 100%;
}

.audio {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.video-local {
  display: flex;
  background: black;
  position: absolute;
  border: 1px solid lightgrey;
  height: 120px;
  width: 120px;
  bottom: 30px;
  left: 20px;
  transform: scaleX(-1);
  z-index: 10000000000;
}

.toggle-aduio {
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 35px;
  z-index: 11111;
  right: 30px;
  z-index: 99999999999999;
  cursor: pointer;
}

.full-screen {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 111;
  cursor: pointer;
}

.button {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 150px;
  // background: rgba(0, 0, 0, 0.6);
  z-index: 1;
  // border-top: 1px solid #DCDCDCFF;
}

.demonstration {
  color: #fff;
  width: 100%;
  text-align: center;
  // position: absolute;
  // top: 30px;
  // margin: 50px 0;
}

/deep/ .loadingbutton {
  width: 0px !important;
  height: 0px;
  background: linear-gradient(to right, #ffffff, #ffffff);
  border: 0px solid;
}

// /deep/ .disabled-row {
//   pointer-events: none;
//   opacity: 0.4;
// }

.cancelButtonClass {
  background-color: #1890ff;
  border-color: #1890ff;
}

#videoRemote {
  margin: 0 auto;
}

#videoLocal {}

.IntercomnotifyDialog {
  width: 280px;
  padding: 10px;
  height: 300px;
  background-color: black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.userAudio {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 2em;

  span {
    width: 0.3em;
    height: 1em;
    background-color: white;
  }

  span:nth-of-type(1) {
    animation: grow 1s -0.45s ease-in-out infinite;
  }

  span:nth-of-type(2) {
    animation: grow 1s -0.3s ease-in-out infinite;
  }

  span:nth-of-type(3) {
    animation: grow 1s -0.15s ease-in-out infinite;
  }

  span:nth-of-type(4) {
    animation: grow 1s ease-in-out infinite;
  }
}

@keyframes grow {

  0%,
  100% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(2);
  }
}

.user_select_none {
  user-select: none !important;
  /* 使用 !important 确保优先级最高 */
  -webkit-user-select: none !important;
  /* 用于 WebKit 浏览器 */
  -moz-user-select: none !important;
  /* 用于 Firefox */
  -ms-user-select: none !important;
  /* 用于 Internet Explorer/Edge */
  -khtml-user-select: none !important;
  /* 用于早期版本的 Konqueror */
  pointer-events: none;
  /* 禁用默认的选择行为 */
}
</style>

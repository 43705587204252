<template>
  <div>
    <el-upload
      ref="my-upload"
      v-model="modelValue"
      :class="{hidePlus:hideUpload}"
      :list-type="listType"
      :action="action"
      :accept="accept"
      :headers="headers"
      :data="data"
      :multiple="multiple"
      :show-file-list="showFileList"
      :limit="limit"
      :name="name"
      :auto-upload="autoUpload"
      :disabled="disabled"
      :preview-file="handlePreview"
      :file-list="fileList"
      :on-preview="onPreview"
      :on-error="onError"
      :on-progress="onProgress"
      :on-change="onChange"
      :before-remove="beforeRemove"
      :on-remove="onRemove"
      :on-success="onSuccess"
      :before-upload="beforeUpload"
      :on-exceed="onExceed"
    >
      <slot name="uploadButton">
        <i class="el-icon-plus" />
      </slot>
    </el-upload>
    <el-dialog :append-to-body="true" :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt>
    </el-dialog>
  </div>
</template>

<script>
import TBaseUpload from '@/components/trina/base/TBaseUpload'
import { getUploadToken, saveFileMeta } from '@/api/modules/qiniu'
import { getCurrentDayStr } from '@/utils/date'

export default {
  name: 'TUploadPic',
  extends: TBaseUpload,
  props: {
    accept: {
      type: String,
      default: 'image/*,.pdf'
    },
    action: {
      type: String,
      default: "https://upload.qiniup.com/"
    },
    listType: {
      type: String,
      default: 'picture-card'
    },
    showFileList : {
      type: Boolean,
      default: true
    },
    imgType: {
      type: String,
      default: ''
    },
    limit: {
      type: Number,
      default: 9
    },
    limitFileSize: {
      type: Number,
      default: 10
    },
    fileList: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      hideUpload: false, // 控制上传按钮的显示与隐藏
      isVideo: true,
      dialogImageUrl: '',
      dialogVisible: false,
      imageServerUrl: '',
    }
  },
  mounted() {
    this.$emit('fileMeta', this.fileList)
  },
  methods: {
    handlePreview(file) {
      console.log(file, '1111111111111111111111111');
    },
    onPreview(file) {
      console.log(file, '快快快快看');
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
      this.emitEventHandler('on-preview', file);
    },
    onRemove(file, fileList) {
      console.log(file, fileList, '删除');
      this.hideUpload = fileList.length >= this.limit;
      this.emitEventHandler('on-remove1', file, fileList,this.imgType);
    },
    async onSuccess(res, file, fileList) {
      console.log(res, file, fileList,'看看');
      
      this.hideUpload = fileList.length >= this.limit;

      this.modelValue = `${this.imageServerUrl}/${res.key}`;
      let fileMeta = {
        fileName: file.name,
        fileType: file.name.substr(file.name.lastIndexOf('.') + 1),
        contextType: file.raw.type,
        fileKey: res.key,
        fileSize: file.size,
        fileUrl: `${this.imageServerUrl}/${res.key}`,
      };
      console.log("文件meta信息", fileMeta);
      this.$emit('fileMeta', fileMeta);
      //保存文件的meta信息
      await saveFileMeta(fileMeta).then(fileIdRes => {
        console.log("文件meta信息保存成功", fileIdRes);
        if (fileIdRes.code == 200) {
          res.data = {};
          res.data.fileId = fileIdRes.data;
          res.data.fileUrl = fileMeta.fileUrl;
        }
      });
      // 添加图片base64的信息
      const reader = new FileReader()
      reader.onload = (e) => {
        res.base64Image = e.target.result
        this.emitEventHandler('on-success', res, file, fileList, this.$refs['my-upload'], this.imgType);

      }
      reader.readAsDataURL(file.raw)

    },
    onError(err, file, fileList) {
      this.emitEventHandler('on-error', err, file, fileList);
    },
    onProgress(event, file, fileList) {
      console.log(event, file, fileList, '进度');
      this.emitEventHandler('on-progress', file, fileList);
    },
    onChange(file, fileList) {
      console.log(file, fileList, '改变');
      this.hideUpload = fileList.length >= this.limit;
      this.emitEventHandler('on-change', file, fileList);
    },
    onExceed(file, fileList) {
      const length = fileList.length;
      if (length === this.limit) {
        this.$message.error('上传数量不能超过' + this.limit);
      }
      this.emitEventHandler('on-exceed', file, fileList);
    },
    beforeRemove(file, fileList) {
      console.log(file, fileList, '删除1121');

      this.emitEventHandler('on-remove', file, fileList,this.imgType);
      //判断是否file.raw存在
      if(file.raw){
        //图片会直接报错的
        if(file.raw.type == 'video/mp4'){
          this.isVideo = true;
        }
      }
    },
    async httpRequest() {
      this.emitEventHandler('http-request');
    },
    async beforeUpload(file) {
      console.log("beforeUpload -> file", file);
      if(file.type == 'video/mp4' && !this.isVideo){
        this.$message.error('只能上传一个视频');
        return ;
      }

      if(file.type == 'video/mp4' && this.isVideo){
        this.isVideo = false;
      }

      let dateYMD = getCurrentDayStr();
      let _this = this;
      //获取上传文件所需的token
      await getUploadToken().then(res => {
        console.log("获取上传文件所需的token", res);
        if (res.code == 200) {
          _this.data.token = res.data.token;
          //自定义文件key
          _this.data.key = dateYMD;
          _this.imageServerUrl = res.data.serverUrl;
        }
      });

      // 判断文件格式
      const hasFileType = true;
      let hasLimitFileSize = true;
      // 判断单个文件大小是否超过限制

      // 判断单个文件大小是否超过限制
      if (this.limitFileSize !== null) {
        hasLimitFileSize = file.size / 1024 < this.limitFileSize * 1024;
        if (!hasLimitFileSize) {
          this.$message.error('上传文件大小不能超过' + this.limitFileSize + 'MB');
          return Promise.reject();
        }
      }
      
      return hasFileType && hasLimitFileSize;
    }
  }
}
</script>

<style  lang="scss" scoped>
/deep/ .hidePlus .el-upload--picture-card {
  display: none !important;
}


/deep/ .el-upload--picture-card {
  border: none !important;
}
</style>

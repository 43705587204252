import request from '@/utils/request';

//所有省份的客户量
export function review() {
  return request({
    url: '/web/view/review',
    method: 'get',
  });
}
//车辆合作方式
export function getCooperationMethod() {
  return request({
    url: '/web/view/getCooperationMethod',
    method: 'get',
  });
}
//人口和面积
export function getPeople() {
  return request({
    url: '/web/view/getPeople',
    method: 'get',
  });
}
//总览右侧数据
export function reviewAll() {
  return request({
    url: '/web/view/reviewAll',
    method: 'get',
  });
}

// 查询实时巡逻人数
export function realTimePatrolV3() {
  return request({
    url: '/web/v2/dashboardV3/realTimePatrolV3',
    method: 'get',
  });
}

// 获取当前辖区区域人员的自定义列排序
export function getCustomColumn() {
  return request({
    url: '/web/v2/dashboardV3/getCustomColumn',
    method: 'get',
  });
}



export function getShortcutFunction() {
  return request({
    url: '/web/v2/dashboardV3/getShortcutFunction',
    method: 'get',
  });
}


// export function getCurrUserInfo() {
//   return request({
//     url: '/web/system/login/getCurrUserInfo',
//     method: 'get',
//   });
// }

// 离线人员（获取选择部门的子孙部门的离线人员）
export function offlineUserDetailV3(data) {
  return request({
    url: '/web/v2/dashboardV3/offlineUserDetailV3',
    method: 'post',
    data
  });
}

// 在线人员（获取选择部门的子孙部门的在线人员）
export function onlineUserDetailV3(data) {
  return request({
    url: '/web/v2/dashboardV3/onlineUserDetailV3',
    method: 'post',
    data
  });
}

// #### 获取用户上线记录详情
export function getUserPatrolLog(data) {
  return request({
    url: '/web/v2/dashboardV3/getUserPatrolLog',
    method: 'post',
    data
  });
}



// #### 保存排序
export function saveCustomColumn(data) {
  return request({
    url: '/web/v2/dashboardV3/saveCustomColumn',
    method: 'post',
    data
  });
}

// 恢复排序
export function restoreDefaultCustomColumn() {
  return request({
    url: '/web/v2/dashboardV3/restoreDefaultCustomColumn',
    method: 'post',
  });
}



export function getParentDeptName(deptId) {
  return request({
    url: '/web/system/user/getParentDeptName/' + deptId,
    method: 'get',
  });
}




// 查询在线人员详情
export function onlineOneUserDetailV3(data) {
  return request({
    url: '/web/v2/dashboardV3/onlineOneUserDetailV3',
    method: 'post',
    data,
  });
}

export function findUserOrBikeOrPoint(id) {
  return request({
    url: '/web/v2/dashboardV3/findUserOrBikeOrPoint/' + id,
    method: 'post',
  });
}
